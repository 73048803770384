import React from 'react'

import { PageNotFound } from '@arch-log/webapp.shared/Errors'

/**
 */
const Page = () => <PageNotFound />

/**
 */
export default Page
